@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #fff1f2;
    /* background-color: #ffffff; */
}

.logo {
    content: url('./assets/SinfullySweetTreats_v2.svg');
}

.homepage {
    background-image: url('./assets/HomePageBG_v3.png');
    height: 85vh;
}

@media only screen and (max-width: 600px) {
    .homepage {
        background-image: url('./assets/HomePageBG_Mobile.png');
        background-color: rgba(0, 0, 0, 0.5); /* Tint color */
        background-blend-mode: multiply;
    }

    .logo {
        content: url('./assets/SS_Logo_White.svg');
    }
}
.notification-banner {
    background-color: #fff1f2;
}

.custom-font {
    /* font-family: 'Bradley Hand'; */
    font-family: 'Bad Script', cursive;
    line-height: 1.5;
}

.bg-beige {
    background-color: #d2c3c2;
}

.navbar,
.card {
    background-color: #d2c3c2;
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@font-face {
    font-family: Cabrito Normal;
    src: url('./assets/fonts/CabritoSerif-ConReg.otf') format('opentype');
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #61dafb;
}

.parallax {
    /* width: 100vw; */
    /* height: 75vh; */
    background-color: rgba(0, 0, 0, 0.2); /* Tint color */
    background-blend-mode: multiply;
    /* background-image: url('./assets/HomePageBG_v2.png'); */
    /* background-size: 100% 100%; */
    /* background-size: 100; */
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.minibio {
    /* font-family: 'Source Serif Pro', serif; */
}

.minibio-desc {
    /* font-family: 'Raleway', sans-serif; */
}

.background-tint {
    background-color: rgb(9, 8, 8); /* Tint color */
    background-blend-mode: multiply;
}
